export const BLOG_NEWS = [
  {
    link: 'https://inside.nhn.com/br/storyDetail.nhn?no=90',
    image: require('@/assets/img/blog/img-news01.jpg'),
    description: 'NHN이 ‘대한민국 인공지능대상’ 패션부문 대상을 수상하기까지',
    date: '2021.11.22'
  },
  {
    link: 'https://inside.nhn.com/br/storyDetail.nhn?no=59',
    image: require('@/assets/img/blog/img-news02.jpg'),
    description: '꿈의 도시에 NHN이 만드는 AI 데이터센터는 어떤 모습일까?',
    date: '2021.02.10'
  },
  {
    link: 'https://www.nhn.com/ko/pr/pressReleaseDetail.nhn?board.boardNum=25552',
    image: require('@/assets/img/blog/img-news03.jpg'),
    description: 'NHN, 패션 분야 특화된 ‘AI FASHION’ 서비스 정식 출시',
    date: '2021.09.29'
  },
  {
    link: 'https://www.nhn.com/ko/pr/pressReleaseDetail.nhn?board.boardNum=25472',
    image: require('@/assets/img/blog/img-news04.jpg'),
    description: 'NHN, 얼굴인식률 100% 만족하며 KISA 바이오인식 인증 획득',
    date: '2021.07.26'
  },
  {
    link: 'https://www.nhn.com/ko/pr/pressReleaseDetail.nhn?board.boardNum=25388',
    image: require('@/assets/img/blog/img-news05.jpg'),
    description: 'NHN-광주정보문화산업진흥원, 게임생태계 활성화 위한 MOU 체결',
    date: '2021.05.11'
  },
  {
    link: 'https://www.nhn.com/ko/pr/pressReleaseDetail.nhn?board.boardNum=25242',
    image: require('@/assets/img/blog/img-news06.jpg'),
    description:
      '기술 컨퍼런스 ‘NHN FORWARD’, 오프닝 노트에 약 2,600명 참석하며 성료',
    date: '2020.12.16'
  }
];

export const BLOG_ENGINEER = [
  {
    link: 'https://www.youtube.com/watch?v=Xm3t-asitPw&list=PL42XJKPNDepZC5HXlqxzTTJ_Ai_KDcXRa&index=8',
    image: require('@/assets/img/blog/img-engineer01.jpg'),
    description: '[NHN FORWARD 2021] 언택트 시대를 위한 얼굴 인식 활용 방법',
    date: ''
  },
  {
    link: 'https://www.youtube.com/watch?v=mFsKko0jQMA&list=PL42XJKPNDepZC5HXlqxzTTJ_Ai_KDcXRa&index=21',
    image: require('@/assets/img/blog/img-engineer02.jpg'),
    description: '[NHN FORWARD 2021] 오픈소스로 쉽게 만드는 기계번역기',
    date: ''
  },
  {
    link: 'https://www.youtube.com/watch?v=iDyovWEmAdA&list=PL42XJKPNDepZbqM9N11RxL5UY_5PbA_Wo&index=27',
    image: require('@/assets/img/blog/img-engineer03.jpg'),
    description: '[NHN FORWARD 2020] 오프닝 노트: AI @ NHN',
    date: ''
  },
  {
    link: 'https://www.youtube.com/watch?v=87a0dZsKWwE&list=PL42XJKPNDepZbqM9N11RxL5UY_5PbA_Wo&index=33',
    image: require('@/assets/img/blog/img-engineer04.jpg'),
    description: '[NHN FORWARD 2020] OCR(광학 문자 인식), OMR 카드 리더기',
    date: ''
  },
  {
    link: 'https://www.youtube.com/watch?v=N7XyqfhY2lU',
    image: require('@/assets/img/blog/img-engineer05.jpg'),
    description: '[2019] 딥러닝을 이용한 가상 피팅 룸',
    date: ''
  },
  {
    link: 'https://www.youtube.com/watch?v=oGPqbvEkfxs',
    image: require('@/assets/img/blog/img-engineer06.jpg'),
    description: '[2019] 패션 시소러스 기반 상품 특징 분석 시스템',
    date: ''
  }
];
