










import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ListBox',
  props: {
    blogListInfo: Object,
    listIndex: Number
  },
  setup(props, { emit }) {
    const liRef: Ref<HTMLUListElement | null> = ref(null);

    onMounted(() => {
      emit('emitSetListPosition', liRef.value!.offsetLeft);
      const oriTime = ((props.listIndex || 0) + 2) * 2;
      if (liRef.value)
        liRef.value.style.animationDelay =
          oriTime > 10 ? `${oriTime / 10}s` : `0.${oriTime}s`;
    });

    return { liRef };
  }
});
