






































































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { BLOG_ENGINEER, BLOG_NEWS } from '@/commons/constants/blog-properties';
import { i18nTxt } from '@/commons/i18n/index';
import ListBox from '@/components/blog/ListBox.vue';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'Blog',
  components: {
    ListBox
  },
  setup(props, { emit }) {
    const isPageStart = ref(false);
    const isListShow = ref(false);
    const mainArea: Ref<any | null> = ref(null);
    const listAreaRef: Ref<HTMLUListElement | null> = ref(null);
    const newsListRef: Ref<HTMLUListElement | null> = ref(null);
    const engineerListRef: Ref<HTMLUListElement | null> = ref(null);
    const listPosition: Ref<number[]> = ref([]);
    const pageIdx = ref({ news: 0, engineer: 0 });
    const lastIdx = ref(3);
    const isPCCheck = isPC();
    const tStartPosX = ref(0);

    const newsPagingHandler = (isNext: boolean) => {
      if (isNext) {
        pageIdx.value.news =
          pageIdx.value.news < lastIdx.value
            ? pageIdx.value.news + 1
            : pageIdx.value.news;
      } else {
        pageIdx.value.news =
          pageIdx.value.news > 0 ? pageIdx.value.news - 1 : 0;
      }
      newsListRef.value!.style.transform = `translateX(-${
        (isPCCheck ? 21.61458 : 83.33334) * pageIdx.value.news
      }vw)`;
      newsListRef.value!.style.transition = 'transform .6s';
    };

    const engineerPagingHandler = (isNext: boolean) => {
      if (isNext) {
        pageIdx.value.engineer =
          pageIdx.value.engineer < lastIdx.value
            ? pageIdx.value.engineer + 1
            : pageIdx.value.engineer;
      } else {
        pageIdx.value.engineer =
          pageIdx.value.engineer > 0 ? pageIdx.value.engineer - 1 : 0;
      }
      engineerListRef.value!.style.transform = `translateX(-${
        (isPCCheck ? 21.61458 : 83.33334) * pageIdx.value.engineer
      }vw)`;
      engineerListRef.value!.style.transition = 'transform .6s';
    };

    const setListPosition = (posX: number) => {
      listPosition.value.push(posX);
    };

    function initObserver() {
      const io = new IntersectionObserver(
        (entries) => {
          entries.map((entry) => {
            if (entry.isIntersecting) {
              isListShow.value = true;
            }
          });
        },
        { threshold: 0.2 }
      );
      io.observe(mainArea.value);
    }

    const newsTouchStartHandler = (evt: TouchEvent) => {
      tStartPosX.value = evt.touches[0].screenX;
    };

    const newsTouchEndHandler = (evt: TouchEvent) => {
      if (Math.abs(tStartPosX.value - evt.changedTouches[0].screenX) < 50)
        return;
      newsPagingHandler(tStartPosX.value > evt.changedTouches[0].screenX);
    };

    const engineerTouchStartHandler = (evt: TouchEvent) => {
      tStartPosX.value = evt.touches[0].screenX;
    };

    const engineerTouchEndHandler = (evt: TouchEvent) => {
      if (Math.abs(tStartPosX.value - evt.changedTouches[0].screenX) < 50)
        return;
      engineerPagingHandler(tStartPosX.value > evt.changedTouches[0].screenX);
    };

    onMounted(() => {
      emit('emitIsHomeFocus', false, true);
      lastIdx.value = isPC() ? 3 : 5;
      setTimeout(() => {
        isPageStart.value = true;
      }, 100);

      if (!isPCCheck) {
        setTimeout(() => {
          isListShow.value = !isPC();
        }, 1000);
        newsListRef.value?.addEventListener(
          'touchstart',
          newsTouchStartHandler
        );
        newsListRef.value?.addEventListener('touchend', newsTouchEndHandler);
        engineerListRef.value?.addEventListener(
          'touchstart',
          engineerTouchStartHandler
        );
        engineerListRef.value?.addEventListener(
          'touchend',
          engineerTouchEndHandler
        );
      } else {
        initObserver();
      }
    });

    return {
      i18nTxt,
      BLOG_NEWS,
      BLOG_ENGINEER,
      isPageStart,
      isListShow,
      mainArea,
      listAreaRef,
      newsListRef,
      engineerListRef,
      pageIdx,
      lastIdx,
      newsPagingHandler,
      engineerPagingHandler,
      setListPosition
    };
  }
});
